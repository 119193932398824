import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircleIcon from '@mui/icons-material/Circle';

function appFriendsData(jsonData) {
  return (jsonData.map(item => {
    return ({
      id: item.sub,
      userStatusColor: (item.user_status === '0') ? 'success' : ((item.user_status === '1') ? 'warning' : 'error'),
      username: item.username
    });
  }))
}

function GetFriendsList() {
  const [loading, setLoading] = useState(true);
  const [friendsData, setFriendsData] = useState([]);
  const [friend, setFriend] = useState([]);

  const handleFriendChange = (event) => {
    setFriend(event.target.value);
    Cookies.set('currentFriendSub', event.target.value, { expires: 7 }); // Cookie expires in 7 days

    friendsData.forEach((item, index) => {
      if (event.target.value === item['id']) {
        Cookies.set('currentFriendName', item['username'], { expires: 7 }); // Cookie expires in 7 days
      }
    });
  };
    
  useEffect(() => {
    const fetchData = async () => {
      if (Cookies.get('connectedToSpotify')) {
        try {

          const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-app-connections-list', {
            headers: {
              "Authorization": "Bearer " + Cookies.get('apiToken')
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setFriendsData(appFriendsData(jsonData))
          // console.log(jsonData)
        } catch (error) {
          setFriendsData('Error fetching friends:', error)
          console.error('Error fetching friends:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        Pick a friend
      </Typography>
      {loading && <div><CircularProgress /></div>}
      {!loading && <div>
        <Box sx={{ height: '100%', width: '100%' }}>
          <FormControl sx={{ width: '150px' }}>
            <InputLabel id="demo-simple-select-label">Friends</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={friend}
              label="Friend"
              onChange={handleFriendChange}
            >
              <MenuItem value="">
                <em>Choose a Friend</em>
              </MenuItem>
              {friendsData.map((item, index) => (
                <MenuItem key={index} value={item.id}><CircleIcon color={item.userStatusColor} /> {item.username}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        </div>}
    </div>
  )
}

export default GetFriendsList;