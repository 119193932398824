import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WelcomePage from "./WelcomePage.js"
import FriendsPage from './FriendsPage';
import AccountPage from './AccountPage.js';
import Callback from "./Callback.js"
import CallbackSpotify from "./CallbackSpotify.js"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

function App() {
  if (!(Cookies.get('currentTab'))) {
    Cookies.set('currentTab', 0);
  }

  const [value, setValue] = React.useState(Number(Cookies.get('currentTab')));

  return (
    <Container maxWidth="sm">
      <Box sx={{ width: 500 }}>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<WelcomePage/>}/>
            <Route path="/friends" element={<FriendsPage/>}/>
            <Route path="/account" element={<AccountPage/>}/>
            <Route path="/callback" element={<Callback/>} />
            <Route path="/callback-spotify" element={<CallbackSpotify/>} />
          </Routes>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            Cookies.set('currentTab', newValue);
          }}
        >
          <BottomNavigationAction component={Link} to="/" label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction component={Link} to="/friends" label="Friends" icon={<Diversity1Icon />} />
          <BottomNavigationAction component={Link} to="/account" label="Account" icon={<AccountCircleIcon />} />
        </BottomNavigation>
      </Paper>
        </BrowserRouter>
      </Box>
    </Container>
);
}

export default App;
