import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';

function spotifyProfileData(jsonData) {
  var spotifyId = ''
  if (jsonData['display_name'] == null) {
    spotifyId = jsonData['email']
  } else {
    spotifyId = jsonData['display_name']
  }

  if (jsonData.hasOwnProperty('id_token')) {
    Cookies.set('apiToken', jsonData['id_token'], { expires: 7 })
  }

  return spotifyId
}

function GetSpotifyProfile() {
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (Cookies.get('connectedToSpotify')) {
        try {

          const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-spotify-profile', {
            headers: {
              "Authorization": "Bearer " + Cookies.get('apiToken')
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setProfileData(spotifyProfileData(jsonData))
        } catch (error) {
          setProfileData('Error fetching Spotify profile:', error)
          console.error('Error fetching Spotify profile:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        Spotify account
      </Typography>
      {loading && <div><CircularProgress /></div>}
      {!loading && <div>{profileData}</div>}
    </div>
  )
}

export default GetSpotifyProfile;