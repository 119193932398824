import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function CallbackSpotify() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code')
  const state = urlParams.get('state')

  useEffect(() => {
    const fetchData = async () => {
      if (Cookies.get('spotifyState') === state) {
        try {
          Cookies.remove('spotifyState') // we already validated it
          const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-spotify-token?code=' + code +
            '&redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback-spotify', {
            headers: {
              "Authorization": "Bearer " + Cookies.get('apiToken')
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          Cookies.set('connectedToSpotify', jsonData['connected_to_spotify'], { expires: 7 })

          navigate('/');
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      {loading && <p>Getting Spotify auth...</p>}
    </div>
  )
}

export default CallbackSpotify;