import Cookies from 'js-cookie';
import GetSpotifyCurrentUserPlayingTrack from './GetSpotifyCurrentUserPlayingTrack';
import GetNormalizedRecentlyPlayed from './GetNormalizedRecentlyPlayed';
import GetNormalizedTopTracks from './GetNormalizedTopTracks';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { loggedIn, connectedToSpotify, ConnectSpotify, connectedToAppleMusic } from './sharedFunctions';
import { Button } from '@mui/material';

const WelcomePage = () => {
  if (Cookies.get('apiToken')) {
    console.log('User is authenticated');
  } else {
    alert("Redirecting to AWS Cognito because you don't have a cookie.");
    window.location.replace('https://' + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + '/login?' +
                'response_type=code&' +
                'client_id=' + process.env.REACT_APP_COGNITO_CLIENT_ID + '&' +
                'redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback');
    console.log('User is not authenticated');
  }
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          Welcome!
        </Typography>
        { loggedIn() && !connectedToSpotify() &&
        <div>
            <Button onClick={ConnectSpotify} variant="contained">Connect Spotify</Button>
        </div>
        }
        { loggedIn() && connectedToSpotify() &&
        <div>
            { GetSpotifyCurrentUserPlayingTrack() }
        </div>
        }
        { loggedIn() && (connectedToAppleMusic() || connectedToSpotify()) &&
        <div>
            { GetNormalizedTopTracks('CurrentUser') }
        </div>
        }
        { loggedIn() && (connectedToAppleMusic() || connectedToSpotify()) &&
        <div>
            { GetNormalizedRecentlyPlayed('CurrentUser') }
        </div>
        }
      </Box>
    </Container>

  );
  };
  
  export default WelcomePage;