import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';

function spotifyCurrentUserPlayingTrackData(jsonData) {
    if (jsonData) {
      if (jsonData.item) {
        return <li key={jsonData.item.id}>
                <img src={jsonData.item.album.images['0'].url} 
                      width='100' height='100' alt='yeah' />
                {jsonData.item.name} - {jsonData.item.artists['0'].name}</li>;
      }
      if (jsonData.currently_playing_type === "ad") {
        return <li key="ad">An ad is playing</li>;
      }
      if (jsonData.currently_playing_type === "episode") {
        return <li key="ad">A podcast episode is playing</li>;
      }
    } else {
      return <li key="yo"> Sorry, nothing is playing!</li>
    }
}

function GetSpotifyCurrentUserPlayingTrack() {
  const [loading, setLoading] = useState(true);
  const [currentUserPlayingTrackData, setCurrentlyPlayingData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (Cookies.get('connectedToSpotify')) {
        try {

          const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-spotify-user-playing-track', {
            headers: {
              "Authorization": "Bearer " + Cookies.get('apiToken')
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setCurrentlyPlayingData(spotifyCurrentUserPlayingTrackData(jsonData))
        } catch (error) {
          setCurrentlyPlayingData('Error fetching Spotify current user playing track:', error)
          console.error('Error fetching Spotify current user playing track:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000); // Fetch every 60 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount

    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        You're currently playing
      </Typography>
      {loading && <div><CircularProgress /></div>}
      {!loading && <div><ul>{currentUserPlayingTrackData}</ul></div>}
    </div>
  )
}

export default GetSpotifyCurrentUserPlayingTrack;