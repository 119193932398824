import Cookies from 'js-cookie';

export function loggedIn() {
  if (Cookies.get('apiToken')) {
    return true
  } else {
    return false
  }
}

export function logout() {
  Cookies.remove('apiToken');
  Cookies.remove('email');
  window.location.reload();
}

export function connectedToSpotify() {
  if (Cookies.get('connectedToSpotify')) {
    return true
  } else {
    return false
  }
}

function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function ConnectSpotify() {
  // calculate state, store in a cookie
  var state = generateRandomString(16);
  Cookies.set('spotifyState',state, { expires: 7 })

  window.location.replace('https://accounts.spotify.com/authorize?response_type=code' +
  '&client_id=' + process.env.REACT_APP_SPOTIFY_CLIENT_ID +
  '&redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback-spotify' +
  '&scope=' + process.env.REACT_APP_SPOTIFY_APP_SCOPE + '&state=' + state);
}

export function connectedToAppleMusic() {
  if (Cookies.get('connectedToAppleMusic')) {
    return true
  } else {
    return false
  }
}

async function getAppleToken() {
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-apple-token', {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const jsonData = await response.json()

  return jsonData
}

async function setAppleUserToken(token) {
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/store-apple-music-user-token?' +
            'user-token=' + encodeURIComponent(token), {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return 'It worked'
}

export function ConnectAppleMusic() {
  getAppleToken().then(jsonData => {
    console.log(jsonData)

    // eslint-disable-next-line no-undef
    MusicKit.configure(jsonData)

    // eslint-disable-next-line no-undef
    let music = MusicKit.getInstance()
    music.authorize().then(function(token) {
      console.log(token)

      setAppleUserToken(token).then(() => {
        Cookies.set('connectedToAppleMusic', true, { expires: 7 })
        window.location.reload()
      })
    }).catch (e => {
      console.log ('Error:' + e )
    })
  })
}
