import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

function spotifyOtherUserPlayingTrackData(jsonData) {
    if (jsonData) {
      if (jsonData.item) {
        return <div>
      <img src={jsonData.item.album.images['0'].url} 
            width='100' height='100' alt='yeah' />
      {jsonData.item.name} - {jsonData.item.artists['0'].name}</div>;

}
      if (jsonData.currently_playing_type === "ad") {
        return <div>An ad is playing</div>;
      }
      if (jsonData.currently_playing_type === "episode") {
        return <div>A podcast episode is playing</div>;
      }
    } else {
      return <div>Sorry, nothing is playing!</div>
    }
}

function spotifyOtherUserPlayingTrackLikability(jsonData) {
  if (jsonData) {
    if (jsonData.item) {
      return true
    }
  }
  return false
}

function spotifyOtherUserPlayingTrackId(jsonData) {
  if (jsonData) {
    if (jsonData.item) {
      return jsonData.item.id
    }
  }
}

function spotifyOtherUserPlayingTrackLikeCount(jsonData) {
  if (jsonData) {
    return jsonData.like_count
  }
}

function spotifyOtherUserPlayingTrackILike(jsonData) {
  if (jsonData) {
    if (jsonData.i_like === 0) {
      return false
    } else {
      return true
    }
  }
}

function GetSpotifyOtherUserPlayingTrack() {
  const [loading, setLoading] = useState(true);
  const [localFriend, setLocalFriend] = useState(Cookies.get('currentFriendSub'));
  const [otherUsersPlayingTrackData, setCurrentlyPlayingData] = useState([]);
  const [likable, setLikable] = useState(false);
  const [liked, setLiked] = useState(false);
  const [trackId, setTrackId] = useState([]);
  const [like_count, setLikeCount] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (Cookies.get('connectedToSpotify')) {
        try {

          const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-spotify-user-playing-track?' +
            'sub=' + localFriend, {
            headers: {
              "Authorization": "Bearer " + Cookies.get('apiToken')
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setCurrentlyPlayingData(spotifyOtherUserPlayingTrackData(jsonData))
          setLikable(spotifyOtherUserPlayingTrackLikability(jsonData))
          setTrackId(spotifyOtherUserPlayingTrackId(jsonData))
          setLikeCount(spotifyOtherUserPlayingTrackLikeCount(jsonData))
          setLiked(spotifyOtherUserPlayingTrackILike(jsonData))
          console.log(jsonData)
        } catch (error) {
          setCurrentlyPlayingData('Error fetching Spotify other users playing track:', error)
          console.error('Error fetching Spotify other users playing track:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000); // Fetch every 60 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount

  }, [localFriend]);

  // Update friend with every render when its value has changed.
  const friendCookie = Cookies.get("currentFriendSub");
  if (localFriend !== friendCookie ) {
    setLocalFriend(friendCookie);
  }

  const handleChange = (event, newLiked) => {
    fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/store-spotify-track-like?' +
      'sub=' + localFriend +
      '&trackId=' + trackId, {
      headers: {
        "Authorization": "Bearer " + Cookies.get('apiToken')
      },
    });

    setLiked(!liked);
    if (!liked) {
      setLikeCount(like_count+1);
    } else {
      setLikeCount(like_count-1);
    }
  };

  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        Your friend, {Cookies.get("currentFriendName")}, is currently playing
      </Typography>
      {loading && <div><CircularProgress /></div>}
      {!loading && <div>
        {likable &&
          <ToggleButton
            color="error"
            value="check"
            selected={liked}
            // onChange={() => setLiked((prevSelected) => !prevSelected)}
            onChange={handleChange}
          >
            {liked ? <FavoriteIcon color="error"/> : <FavoriteBorderIcon color="error"/> }
          </ToggleButton>
        }
        {like_count}
        {otherUsersPlayingTrackData}</div>}
    </div>
  );
}

export default GetSpotifyOtherUserPlayingTrack;