import * as React from 'react';
import Cookies from 'js-cookie';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GetFriendsList from './GetFriendsList';
import GetSpotifyOtherUserPlayingTrack from './GetSpotifyOtherUserPlayingTrack';
import GetNormalizedRecentlyPlayed from './GetNormalizedRecentlyPlayed';
import GetNormalizedTopTracks from './GetNormalizedTopTracks';

function loggedIn() {
  if (Cookies.get('apiToken')) {
    return true
  } else {
    return false
  }
}

const FriendsPage = () => {
  if (Cookies.get('apiToken')) {
    console.log('User is authenticated');
  } else {
    alert("Redirecting to AWS Cognito because you don't have a cookie.");
    window.location.replace('https://' + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + '/login?' +
                'response_type=code&' +
                'client_id=' + process.env.REACT_APP_COGNITO_CLIENT_ID + '&' +
                'redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback');
    console.log('User is not authenticated');
  }
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          Friends
        </Typography>
        { loggedIn() &&
          <div>
              { GetFriendsList() }
              { GetSpotifyOtherUserPlayingTrack() }
              { GetNormalizedTopTracks('Friend') }
              { GetNormalizedRecentlyPlayed('Friend') }
          </div>
        }
      </Box>
    </Container>

  );
  };
  
  export default FriendsPage;